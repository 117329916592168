import { OFFER_TYPE } from '~served/configs';
import { UndefinedOrNull } from '~served/types/utils';

import { MongoDocument } from '../calculation';
import { getDiscountAmount } from '../getDiscountAmount';
import { bigMath } from '../misc';

const getAppliedOfferByItems = (items, offer?) => {
	const eligibleItems: { [k: string]: number } = {};

	if (!offer || !offer?.configs.length) return { offer: null, eligibleItems, offerAmount: 0 };

	// doesnt allow for receipt offer in get offer by items
	if (isReceiptOffer(offer)) return { offer: null, eligibleItems, offerAmount: 0 };

	items.forEach((orderItem) => {
		const itemTotalPrice = orderItem.subtotal || orderItem.totalPrice || 0;

		offer.configs.forEach((config) => {
			switch (config.type) {
				case OFFER_TYPE.ITEMS:
					if (config.items.includes(orderItem.item.toString())) {
						const { discountAmount } = getDiscountAmount(itemTotalPrice, {
							type: config.discount_type,
							value: config.discount_value,
						});

						if (!eligibleItems[orderItem._id]) {
							eligibleItems[orderItem._id] =
								discountAmount > itemTotalPrice ? itemTotalPrice : discountAmount;
						} else if (eligibleItems[orderItem._id]) {
							if (
								(offer.is_offering_higher_value && discountAmount > eligibleItems[orderItem._id]) ||
								(!offer.is_offering_higher_value && discountAmount < eligibleItems[orderItem._id])
							) {
								eligibleItems[orderItem._id] =
									discountAmount > itemTotalPrice ? itemTotalPrice : discountAmount;
							}
						}
					}
					break;
				case OFFER_TYPE.CATEGORIES:
					if (config.categories.includes(orderItem.category.toString())) {
						const { discountAmount } = getDiscountAmount(itemTotalPrice, {
							type: config.discount_type,
							value: config.discount_value,
						});

						if (!eligibleItems[orderItem._id]) {
							eligibleItems[orderItem._id] =
								discountAmount > itemTotalPrice ? itemTotalPrice : discountAmount;
						} else if (eligibleItems[orderItem._id]) {
							if (
								(offer.is_offering_higher_value && discountAmount > eligibleItems[orderItem._id]) ||
								(!offer.is_offering_higher_value && discountAmount < eligibleItems[orderItem._id])
							) {
								eligibleItems[orderItem._id] =
									discountAmount > itemTotalPrice ? itemTotalPrice : discountAmount;
							}
						}
					}
					break;
				case OFFER_TYPE.ITEM_TYPES:
					if (config.item_types.includes(orderItem.type)) {
						const { discountAmount } = getDiscountAmount(
							orderItem.subtotal || (orderItem as any).totalPrice || 0,
							{
								type: config.discount_type,
								value: config.discount_value,
							}
						);

						if (!eligibleItems[orderItem._id]) {
							eligibleItems[orderItem._id] =
								discountAmount > itemTotalPrice ? itemTotalPrice : discountAmount;
						} else if (eligibleItems[orderItem._id]) {
							if (
								(offer.is_offering_higher_value && discountAmount > eligibleItems[orderItem._id]) ||
								(!offer.is_offering_higher_value && discountAmount < eligibleItems[orderItem._id])
							) {
								eligibleItems[orderItem._id] =
									discountAmount > itemTotalPrice ? itemTotalPrice : discountAmount;
							}
						}
					}
					break;
			}
		});
	});

	return {
		offer,
		eligibleItems,
		offerAmount: Object.values(eligibleItems).reduce((pre, cur) => bigMath.add(pre, cur), 0) as number,
	};
};

const getAppliedOfferByReceipt = (amount: number, offer?: UndefinedOrNull<MongoDocument>) => {
	let offerAmount = 0;

	if (!offer || !offer?.configs.length || !amount) return { offer: offer ?? null, offerAmount };

	// doesnt allow for other type offer in get offer by receipt
	if (!isReceiptOffer(offer)) return { offer: null, offerAmount };

	offer.configs.forEach((config) => {
		const { discountAmount } = getDiscountAmount(amount, {
			type: config.discount_type,
			value: config.discount_value,
		});
		if (
			(offer.is_offering_higher_value && discountAmount > offerAmount) ||
			(!offer.is_offering_higher_value && discountAmount < offerAmount)
		) {
			offerAmount = discountAmount;
		}
	});

	return {
		offer,
		offerAmount,
	};
};

const isReceiptOffer = (offer?: UndefinedOrNull<MongoDocument>) => {
	return (offer?.configs || []).some((config) => config.type === OFFER_TYPE.RECEIPTS);
};

export { getAppliedOfferByItems, getAppliedOfferByReceipt, isReceiptOffer };
