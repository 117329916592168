import { useCallback, useRef, useState } from 'react';

import type { MutableRefObject } from 'react';

export interface PopoverController<T, S> {
	anchorRef: MutableRefObject<T | null>;
	isOpen: boolean;
	data?: S;
	handleOpen: (data?: S) => void;
	handleClose: () => void;
	handleToggle: () => void;
}

export function usePopover<T = HTMLElement, S = unknown>(initData?: S): PopoverController<T, S> {
	const anchorRef = useRef<T | null>(null);

	const [state, setState] = useState({
		open: false,
		data: initData,
	});

	const handleOpen = useCallback((data?: S): void => {
		setState({ open: true, data });
	}, []);

	const handleClose = useCallback((): void => {
		setState({ open: false, data: initData });
	}, []);

	const handleToggle = useCallback((): void => {
		setState((prevState) => ({ open: !prevState.open, data: prevState.data }));
	}, []);

	return {
		anchorRef,
		isOpen: state.open,
		data: state.data,
		handleClose,
		handleOpen,
		handleToggle,
	};
}
