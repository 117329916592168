import { Alert, Snackbar } from '@mui/material';
import { useError, useSetError } from '~/store';
const ErrorAlert = () => {
  const error = useError();
  const setError = useSetError();
  const onClose = () => {
    setError(null);
  };
  if (!error) return null;
  return <Snackbar open autoHideDuration={3000} anchorOrigin={{
    vertical: 'top',
    horizontal: 'center'
  }} onClose={onClose} data-sentry-element="Snackbar" data-sentry-component="ErrorAlert" data-sentry-source-file="ErrorAlert.tsx">
			<Alert onClose={onClose} severity="error" data-sentry-element="Alert" data-sentry-source-file="ErrorAlert.tsx">
				{error}
			</Alert>
		</Snackbar>;
};
export { ErrorAlert };