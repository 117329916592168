import Big from 'big.js';

import { DISCOUNT_TYPE } from '~served/configs';

export type Discount = { type: 'amount' | 'percentage'; value: number };

export function getDiscountAmount(amount: number, discount?: Discount) {
	let discountAmount = 0;

	if (!discount?.type || !discount?.value) {
		discountAmount = 0;
	} else {
		if (discount?.type === DISCOUNT_TYPE.AMOUNT) {
			discountAmount = discount.value;
		}
		if (discount?.type === DISCOUNT_TYPE.PERCENTAGE)
			discountAmount = new Big(amount).mul(discount.value).div(100).round(2).toNumber();
	}

	const discountedAmount = new Big(amount).minus(discountAmount).round(2).toNumber();

	return {
		discountAmount,
		/** @deprecated for naming convention, use `discountedAmount` instead */
		amountAfterDiscount: discountedAmount,
		discountedAmount,
	};
}
