import { Client } from 'graphql-ws';

import { setState } from '~/store';
import {
	SubscribeToSystemHealthSubscription,
	System_Health_Subscription_Event_Type,
} from '~/types/__generated/gql/graphql';

const query = /* GraphQL */ `
	subscription SubscribeToSystemHealth {
		subscribeToSystemHealth {
			type
		}
	}
`;

export const subscribeToSystemHealth = async (client: Client) => {
	const subscription = client.iterate<SubscribeToSystemHealthSubscription>({ query });
	for await (const result of subscription) {
		if (result.data) {
			const { type } = result.data.subscribeToSystemHealth;
			switch (type) {
				case System_Health_Subscription_Event_Type.Init: {
					setState({ isConnectedToServer: true });
					break;
				}
				case System_Health_Subscription_Event_Type.NewVersionAvailable: {
					setState({ isLatest: false });
					break;
				}
				case System_Health_Subscription_Event_Type.Shutdown: {
					setState({ isConnectedToServer: false });
					break;
				}
			}
		}
	}
};
