function pluck<T, K extends keyof T>(o: T, keys: K[]): { [k in K]: T[K] } {
	return keys.reduce<any>((pre, cur) => ({ ...pre, [cur]: o[cur] }), {});
}

const omit = <T extends Record<string, unknown>, K extends [...(keyof T)[]]>(
	originalObject: T,
	keysToOmit: K
): {
	[K2 in Exclude<keyof T, K[number]>]: T[K2];
} => {
	const clonedObject = { ...originalObject };

	for (const path of keysToOmit) {
		delete clonedObject[path];
	}

	return clonedObject;
};

export { omit, pluck };
