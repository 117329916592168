import { dayjs } from '~served/utils';

import { getPayloadFromToken } from './getPayloadFromToken';

const validateSessionFromUrl = () => {
	const url = new URL(window.location.href);

	const token = url.searchParams.get('token');
	// `iat` and `exp` are in seconds
	const { iat: iatSecs, exp: expSecs } = token ? getPayloadFromToken(token) : { iat: 0, exp: 0 };
	const { iat, exp } = { iat: iatSecs * 1000, exp: expSecs * 1000 };

	const pathname = url.pathname;

	const isPathnameSessionSensitive = () => {
		return ['/menu', '/cart'].includes(pathname);
	};

	const isSessionExpired = () => {
		if (!iat || !exp) return true;
		return dayjs().isAfter(dayjs(exp));
	};

	return {
		iat: iat,
		exp: exp,
		pathname,
		isPathnameSessionSensitive,
		isSessionExpired,
	};
};

export { validateSessionFromUrl };
