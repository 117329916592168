const CORS_OPTIONS = {
	origin: [
		// Consumer app
		'http://127.0.0.1:3000',
		'http://localhost:3000',
		'https://consumer.getservednow.com',
		'https://staging-consumer.getservednow.com',
		'https://test-consumer.getservednow.com',
		'https://consumer.served.to',
		'https://staging-consumer.served.to',
		'https://test-consumer.served.to',

		// Staff app
		'http://127.0.0.1:3001',
		'http://localhost:3001',
		'https://staff.getservednow.com',
		'https://staging-staff.getservednow.com',
		'https://test-staff.getservednow.com',
		'https://staff.served.to',
		'https://staging-staff.served.to',
		'https://test-staff.served.to',
	],
	credentials: true,
};

export { CORS_OPTIONS };
