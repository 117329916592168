import localforage from 'localforage';

const LOCAL_STORAGE_KEYS = {
	TOKEN: 'served:consumer:token',
	DEVICE_ID: 'served:consumer:deviceId',
	CUSTOMER_UID: 'served:consumer:customerUid',
};

const browserStorage = {
	token: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.TOKEN),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.TOKEN, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.TOKEN),
	},
	deviceId: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.DEVICE_ID),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.DEVICE_ID, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.DEVICE_ID),
	},
	customerUid: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.CUSTOMER_UID),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.CUSTOMER_UID, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.CUSTOMER_UID),
	},
};

export { browserStorage };
