import Big, { BigSource } from 'big.js';
import { ConfigType } from 'dayjs';

import { dayjs } from '../libs/dayjs';

/** App wide number formatter */
export function formatNumber(str?: BigSource, fixed = 2) {
	const arr = new Big(str || 0).round(fixed).toFixed(fixed).split('.');
	arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	return arr.join('.');
}

/** App wide date formatter */
export function formatDate(dateString: ConfigType, dateOnly = false, dayOfWeek = false) {
	const formatStr =
		(dayOfWeek ? 'ddd ' : '') +
		'MMM DD'
			.concat(dayjs(dateString).isSame(dayjs(), 'y') ? '' : ', YYYY')
			.concat(dateOnly ? '' : ' [\u00B7] hh:mm a');

	return dayjs(dateString).format(formatStr);
}

export function getDiffTimes(from, to?) {
	const fromDate = dayjs(from);
	const toDate = dayjs(to);

	const days = toDate.diff(fromDate, 'days');
	const hours = days === 0 ? toDate.diff(fromDate, 'hours') : toDate.diff(fromDate, 'hours') % days;
	const minutes = hours === 0 ? toDate.diff(fromDate, 'minutes') : toDate.diff(fromDate, 'minutes') % hours;

	return { days, hours, minutes };
}

interface IBoringAvatarProps {
	size?: number;
	name: string;
	variant?: 'marble' | 'beam' | 'pixel' | 'sunset' | 'ring' | 'bauhaus';
	colors?: string[];
	square?: boolean;
}

export function getRandomImage(opts?: IBoringAvatarProps) {
	const {
		size = 40,
		name = 'Served',
		variant = 'beam',
		colors = ['fb7314', '1f202a', 'e2dcd7', '952457'],
		square = false,
	} = opts || {};

	return `https://source.boringavatars.com/${variant}/${size}/${name}?colors=${colors.join(',')}&square=${
		square ? true : false
	}`;
}

/**
 * Convert passed object to url-friendly query string
 */
export function serializeObject(obj: object) {
	const str: string[] = [];

	for (const p in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(p)) {
			const q = encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]);
			str.push(q);
		}
	}

	return str.join('&');
}

/** Simplified all basic bigjs operations */
export const bigMath = {
	add: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>((prev, cur) => new Big(prev).add(cur || 0), new Big(base || 0))
			.round(2)
			.toNumber(),
	sub: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>((prev, cur) => new Big(prev).sub(cur || 0), new Big(base || 0))
			.round(2)
			.toNumber(),
	mul: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>((prev, cur) => new Big(prev).mul(cur || 0), new Big(base || 0))
			.round(2)
			.toNumber(),
	div: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>((prev, cur) => new Big(prev).div(cur || 1), new Big(base || 0))
			.round(2)
			.toNumber(),
	mod: (base: BigSource = 0, ...values: (BigSource | undefined)[]) =>
		values
			.reduce<Big>((prev, cur) => new Big(prev).mod(cur || 1), new Big(base || 0))
			.round(2)
			.toNumber(),
};

export const isUndefinedOrNull = (val: any): val is undefined | null => val === undefined || val === null;

export type GetFullNameParameters = { first_name: string; last_name: string };

export const getFullName = ({ first_name, last_name }: GetFullNameParameters) =>
	[first_name, last_name].join(' ');
