import getSymbolFromCurrency from '@dcspark/currency-symbol-map';

import { PAYMENT_TYPE_TITLE } from '~served/configs';
import { UndefinedOrNull } from '~served/types/utils';

/** Get payment title from enum, passed venue params if value might be of custom payment types */
const getPaymentTitle = (type: string, venue?: UndefinedOrNull<MongoDocument>) => {
	if (PAYMENT_TYPE_TITLE[type]) return PAYMENT_TYPE_TITLE[type];

	const customPaymentType = venue && venue.custom_payment_types.find((t) => t.value === type);
	return customPaymentType ? customPaymentType.label : type;
};

const getOrderSource = (order?: UndefinedOrNull<MongoDocument>) => {
	const isStaff = order?.source && order.source === 'till';
	const isQR = order?.source && order.source === 'qr';
	const sourceTitle = (isStaff && 'Till') || (isQR && 'QR') || 'N/A';

	return { isStaff, isQR, sourceTitle };
};

import { MongoDocument } from '../calculation';
import { formatNumber } from '../misc';

type CurrencyConfig = {
	code: string;
	rate: number;
	previous_rate?: number;
	is_primary_currency: boolean;
	is_secondary_currency: boolean;
	is_deactivated: boolean;
};

const orderDataHelper = (order?: UndefinedOrNull<MongoDocument>) => {
	const getPrimaryCurrency = (): CurrencyConfig => {
		return order?.currencies_configs?.find((c) => c.is_primary_currency === true);
	};

	/** Get only the first matched secondary currency */
	const getSecondaryCurrency = (): CurrencyConfig | undefined => {
		return order?.currencies_configs?.find((c) => c.is_secondary_currency === true && !c.is_deactivated);
	};

	/** Get all secondary currencies */
	const getSecondaryCurrencies = (): CurrencyConfig[] => {
		return (
			order?.currencies_configs?.filter((c) => c.is_secondary_currency === true && !c.is_deactivated) || []
		);
	};

	const getPrimaryCurrencySymbol = () => {
		const primaryCurrency = getPrimaryCurrency();
		return primaryCurrency ? getSymbolFromCurrency(primaryCurrency.code) : '�';
	};

	const getSecondaryCurrencySymbol = () => {
		const secondaryCurrency = getSecondaryCurrency();
		return secondaryCurrency ? getSymbolFromCurrency(secondaryCurrency.code) : '�';
	};

	/** Display number with venue primary currency */
	const $d = (amount: number) => {
		const symbol = getPrimaryCurrencySymbol();
		const absAmount = Math.abs(amount);

		return amount < 0 ? `- ${symbol} ${formatNumber(absAmount)}` : `${symbol} ${formatNumber(absAmount)}`;
	};

	/** Display number with venue secondary currency (if any) */
	const $d2n = (amount: number) => {
		const symbol = getSecondaryCurrencySymbol();
		const absAmount = Math.abs(amount);

		return amount < 0 ? `- ${symbol} ${formatNumber(absAmount)}` : `${symbol} ${formatNumber(absAmount)}`;
	};

	return {
		getPrimaryCurrency,
		getPrimaryCurrencySymbol,
		getSecondaryCurrency,
		getSecondaryCurrencySymbol,
		getSecondaryCurrencies,
		/** Display number with order primary currency */
		$d,
		/** Display number with order secondary currency (if any) */
		$d2n,
	};
};

export { getOrderSource, getPaymentTitle, orderDataHelper };
