import { useCallback, useState } from 'react';

interface DialogController<T> {
	data?: T;
	handleClose: () => void;
	handleOpen: (data?: T) => void;
	open: boolean;
	handleToggle: () => void;
}

export function useDialog<T = unknown>(initData?: T): DialogController<T> {
	const [state, setState] = useState<{ open: boolean; data?: T }>({
		open: false,
		data: initData,
	});

	const handleOpen = useCallback((data?: T): void => {
		setState({ open: true, data });
	}, []);

	const handleClose = useCallback((): void => {
		setState({ open: false, data: initData });
	}, []);

	const handleToggle = useCallback((): void => {
		setState((prevState) => ({ open: !prevState.open, data: prevState.data }));
	}, []);

	return {
		data: state.data,
		open: state.open,
		handleClose,
		handleOpen,
		handleToggle,
	};
}
