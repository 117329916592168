import { isSupported, logEvent } from 'firebase/analytics';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { analytics } from './firebase';

const useRouterEvent = () => {
	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = () => {
			logEvent(analytics, 'page_view', router);
		};

		isSupported().then(() => {
			router.events.on('routeChangeComplete', handleRouteChange);
		});

		return () => {
			isSupported().then(() => {
				router.events.off('routeChangeComplete', handleRouteChange);
			});
		};
	}, []);
};

export { useRouterEvent };
