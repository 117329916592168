import { Box, Typography } from '@mui/material';
const Error = ({
  src = '/images/500.webp',
  title = 'OOPS!',
  description = 'Something went wrong. Check back with us later.'
}) => {
  return <Box data-testid="AppError" height="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center" px={4} gap={2} textAlign="center" data-sentry-element="Box" data-sentry-component="Error" data-sentry-source-file="index.tsx">
			<img src={src} width="250px" />
			<Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="index.tsx">{title}</Typography>
			<Typography variant="body2" color="GrayText" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
				{description}
			</Typography>
		</Box>;
};
export { Error };