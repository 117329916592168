import dayjs, { ConfigType } from 'dayjs';
import { html } from 'common-tags';

import { DISCOUNT_TYPE } from '~served/configs';
import { UndefinedOrNull } from '~served/types/utils';

import { calculateOrderGroup } from '../calculation';
import { convertCurrencies } from '../convertCurrencies';
import { isReceiptOffer } from '../getAppliedOffer';
import locales from '../locales/locales.json';
import { bigMath, formatNumber, getFullName, GetFullNameParameters } from '../misc';
import { getPaymentTitle, orderDataHelper } from '../order';
import { toVenueTime } from '../venue';

import { getStyleFragment } from './fragments/get-style-fragment';
import { GenerateInvoiceTemplateParameters } from './invoice';
import { getCombinedNotes, getCombinedOffers } from './utils';

type PaymentRecord = {
	payment_type: string;
	code: string;
	amount: number;
};
type CashPaymentRecord = {
	code: string;
	amount: number;
};
type ReceiptFields = {
	receipt_number: string;
	headcount: number;
	payment_types: Array<PaymentRecord>;
	cash_received: Array<CashPaymentRecord>;
	cash_returned: Array<CashPaymentRecord>;
	tips: Array<PaymentRecord>;
	rounding_difference_amount: number;
};
type GenerateVenueReceiptTemplateParametersPrintLog = {
	created_at: ConfigType;
	comments?: UndefinedOrNull<string>;
};
export type GenerateVenueReceiptTemplateParameters = Omit<GenerateInvoiceTemplateParameters, 'split'> & {
	language?: string | null;
	customer?: UndefinedOrNull<GetFullNameParameters>;
	printLog: GenerateVenueReceiptTemplateParametersPrintLog & ReceiptFields;
};
export const generateVenueReceiptTemplate = ({
	language,
	location,
	orders,
	customer,
	printLog,
	staff,
	venue,
}: GenerateVenueReceiptTemplateParameters) => {
	const allItems = orders.map((o) => o.items).flat();
	const allCancelledItems = orders.map((o) => o.cancelled_items!).flat();

	if (!allItems.length && !allCancelledItems.length) throw new Error('No items to print');

	const refOrder = orders[0];
	const combinedNotes = getCombinedNotes(orders);
	const offersUsed = getCombinedOffers(allItems);
	const {
		all: {
			subtotal,
			offer_amount,
			discount_amount,
			vat_amount,
			service_charge_amount,
			adjustment_amount,
			grand_total: _grand_total,
		},
	} = calculateOrderGroup(orders);
	const grand_total = bigMath.add(_grand_total, printLog.rounding_difference_amount);
	const vatAmountToDisplay = venue.is_vat_buried ? 0 : vat_amount;
	const serviceChargeAmountToDisplay = venue.is_service_charge_buried ? 0 : service_charge_amount;
	const staffToDisplay = staff ? getFullName(staff) : '-';
	const printDate = toVenueTime(printLog ? printLog.created_at : dayjs(), venue);
	const orderedDate = toVenueTime(refOrder.created_at, venue);
	const customNoteInstruction = venue.custom_note_instructions || 'Note';
	const { $d, $d2n, getPrimaryCurrency, getPrimaryCurrencySymbol, getSecondaryCurrency } =
		orderDataHelper(refOrder);
	const primaryCurrency = getPrimaryCurrency();
	const primaryCurrencySymbol = getPrimaryCurrencySymbol();
	const secondaryCurrency = getSecondaryCurrency();
	const grandTotalSecondary = secondaryCurrency
		? convertCurrencies<string>(grand_total, primaryCurrency.code, refOrder.currencies_configs)[
				secondaryCurrency.code
		  ]
		: 0;
	const localizedText = language ? locales[language] : locales['en'];

	return html`
		<html>
			<head>
				${getStyleFragment(venue, 'receipt')}
			</head>
			<body>
				<div class="container">
					<div class="centered my-1">
						<img id="logo" src="${venue.logo}" alt="Logo" />
					</div>
					<div class="centered light-bold my-1">${venue.name}</div>
					<div class="centered text-sm">${venue.address}</div>
					${venue.vat_tin ? `<div class="centered text-sm">VAT TIN: ${venue.vat_tin}</div>` : ''}

					<br />

					<div class="text-sm my-1">${localizedText.receipt} #: ${printLog.receipt_number}</div>
					<div class="text-sm my-1">
						${localizedText.orders} #: ${orders.map((o) => `#${o.index}`).join(', ')}
					</div>
					${combinedNotes ? `<div class="text-sm my-1">${customNoteInstruction}: ${combinedNotes}</div>` : ''}
					${printLog.headcount
						? `<div class="text-sm my-1"># ${localizedText.ofPeople}: ${printLog.headcount}</div>`
						: ''}
					${customer
						? `<div class="text-sm my-1">${localizedText.customer}: ${getFullName(customer)}</div>`
						: ''}
					<div class="text-sm my-1">
						${localizedText.staff}: ${staffToDisplay}, ${localizedText.location}: ${location.name}
					</div>
					<div class="text-sm my-1">${localizedText.print}: ${printDate}</div>
					<div class="text-sm my-1">${localizedText.ordered}: ${orderedDate}</div>

					<hr class="divider" />

					<div class="row light-bold my-1">
						<div class="col-qty">${localizedText.qty}</div>
						<div class="col-item">${localizedText.item}</div>
						<div class="col-price">${localizedText.price}</div>
					</div>

					${allItems
						.map((item) => {
							return `
								<div class="row my-1">
									<div class="col-qty">${item.quantity}x</div>
									<div class="col-item">${item.title}</div>
									<div class="col-price">${$d(item.subtotal)}</div>
								</div>

								${
									item.options.length
										? item.options
												.map((option) => {
													return `
														<div class="row my-1">
															<div class="col-qty"></div>
															<div class="col-item">+ ${option.quantity}x ${option.title}</div>
															<div class="col-price"></div>
														</div>
													`;
												})
												.join('\n')
										: ''
								}

                ${
									item.subtotal_addons?.offer?.amount &&
									item.subtotal_addons?.offer?.metadata &&
									!isReceiptOffer(item.subtotal_addons?.offer?.metadata)
										? `
                      <div class="row">
                        <div class="col-qty"></div>
                        <div class="col-item">- ${localizedText.offer} "${
												item.subtotal_addons?.offer?.metadata?.title
										  }"</div>
                        <div class="col-price">${$d(item.subtotal_addons?.offer?.amount)}</div>
                      </div>
                    `
										: ''
								}

                ${
									item.subtotal_addons?.discount?.value && !item.subtotal_addons?.discount?.is_divided
										? `
                      <div class="row">
                        <div class="col-qty"></div>
                        <div class="col-item">
                          - ${
														item.subtotal_addons?.discount?.type === DISCOUNT_TYPE.PERCENTAGE
															? '%'
															: primaryCurrencySymbol
													}${item.subtotal_addons?.discount?.value}
                        </div>
                        <div class="col-price">${$d(item.subtotal_addons?.discount?.amount)}</div>
                      </div>
                    `
										: ''
								}
							`;
						})
						.join('\n')}
					${allCancelledItems.length
						? `
									<hr class="divider" />
									<div class="centered text-sm light-bold my-1">CANCELLED ITEMS</div>
								`
						: ''}
					${allCancelledItems
						.map((item) => {
							return `
								<div class="row my-1">
									<div class="col-qty">${item.quantity}x</div>
									<div class="col-item">${item.title}</div>
									<div class="col-price">${$d(item.subtotal)}</div>
								</div>

								${
									item.options.length
										? item.options
												.map((option) => {
													return `
														<div class="row my-1">
															<div class="col-qty"></div>
															<div class="col-item">+ ${option.quantity}x ${option.title}</div>
															<div class="col-price"></div>
														</div>
													`;
												})
												.join('\n')
										: ''
								}

                ${
									item.subtotal_addons?.offer?.amount &&
									item.subtotal_addons?.offer?.metadata &&
									!isReceiptOffer(item.subtotal_addons?.offer?.metadata)
										? `
                      <div class="row">
                        <div class="col-qty"></div>
                        <div class="col-item">- Offer "${item.subtotal_addons?.offer?.metadata?.title}"</div>
                        <div class="col-price">${$d(item.subtotal_addons?.offer?.amount)}</div>
                      </div>
                    `
										: ''
								}

                ${
									item.subtotal_addons?.discount?.value && !item.subtotal_addons?.discount?.is_divided
										? `
                      <div class="row">
                        <div class="col-qty"></div>
                        <div class="col-item">
                          - ${
														item.subtotal_addons?.discount?.type === DISCOUNT_TYPE.PERCENTAGE
															? '%'
															: primaryCurrencySymbol
													}${item.subtotal_addons?.discount?.value}
                        </div>
                        <div class="col-price">${$d(item.subtotal_addons?.discount?.amount)}</div>
                      </div>
                    `
										: ''
								}
							`;
						})
						.join('\n')}

					<hr class="divider" />

					<div class="my-1">
						${localizedText.subtotal}: <span class="float-right pr-2">${$d(subtotal)}</span>
					</div>
					${offer_amount ? `<div class="my-1">${localizedText.offer}:</div>` : ''}
					${offersUsed.length
						? offersUsed
								.map((offer) => {
									return `
											<div class="my-1">
												- ${offer.metadata.title}
                        <span class="float-right pr-2">${$d(offer.amount)}</span>
											</div>
										`;
								})
								.join('\n')
						: ''}
					${discount_amount
						? `<div class="my-1">${localizedText.discount}: <span class="float-right pr-2">${$d(
								discount_amount
						  )}</span></div>`
						: ''}
					${vatAmountToDisplay
						? `<div class="my-1">${localizedText.vat}${
								venue.vat > 0 ? ` ${bigMath.mul(venue.vat, 100)}%` : ''
						  } : <span class="float-right pr-2">${$d(vatAmountToDisplay)}</span></div>`
						: ''}
					${serviceChargeAmountToDisplay
						? `<div class="my-1">${localizedText.serviceCharge}${
								venue.service_charge > 0 ? ` ${bigMath.mul(venue.service_charge, 100)}%` : ''
						  } : <span class="float-right pr-2">${$d(serviceChargeAmountToDisplay)}</span></div>`
						: ''}
					${adjustment_amount
						? `<div class="my-1">${localizedText.adjustment}: <span class="float-right pr-2">${$d(
								adjustment_amount
						  )}</span></div>`
						: ''}
					<div class="my-1">
						${localizedText.grandTotal} (${primaryCurrency?.code}):
						<span class="light-bold float-right pr-2">${$d(grand_total)}</span>
					</div>
					${secondaryCurrency
						? `
                <div class="my-1">
                  ${localizedText.grandTotal} (${
								secondaryCurrency.code
						  }): <span class="light-bold float-right pr-2 line-height-1">${$d2n(grandTotalSecondary)}</span>
                </div>
              `
						: ''}
					<div class="my-1 display-flex justify-content-between full-width">
						<div>${localizedText.paymentTypes}:</div>
						<div>
							${printLog.payment_types
								.map(
									(p) =>
										`<div class="text-align-right pr-2">${getPaymentTitle(p.payment_type, venue)} • ${$d(
											p.amount
										)}</div>`
								)
								.join('\n')}
						</div>
					</div>

					${printLog.cash_received.filter((p) => p.amount > 0).length
						? `
                <div class="my-1 display-flex justify-content-between full-width">
                  <div>${localizedText.cashReceived}:</div>
                  <div>
                    ${printLog.cash_received
											.filter((p) => p.amount > 0)
											.map(
												(p) =>
													`<div class="text-align-right pr-2 line-height-1">${p.code} ${formatNumber(
														p.amount
													)}</div>`
											)
											.join('\n')}
                  </div>
                </div>
              `
						: ''}
					${printLog.cash_returned.filter((p) => p.amount > 0).length
						? `
                <div class="my-1 display-flex justify-content-between full-width">
                  <div>${localizedText.cashChanges}:</div>
                  <div>
                    ${printLog.cash_returned
											.filter((p) => p.amount > 0)
											.map(
												(p) =>
													`<div class="text-align-right pr-2 line-height-1">${p.code} ${formatNumber(
														p.amount
													)}</div>`
											)
											.join('\n')}
                  </div>
                </div>
              `
						: ''}
					${printLog.tips.length
						? `
							<div class="my-1 display-flex justify-content-between full-width">
								<div>Tips:</div>
								<div>${printLog.tips
									.map(
										(p) =>
											`<div class="text-align-right pr-2">${getPaymentTitle(p.payment_type, venue)} • ${$d(
												p.amount
											)}</div>`
									)
									.join('\n')}</div>
							</div>
						`
						: ''}

					<br />

					${venue.custom_printing_fields
						.map(
							(field) =>
								`<div class="my-1">${field.label}: <span class="float-right pr-2">________________</span></div>`
						)
						.join('\n')}
				</div>
			</body>
		</html>
	`
		.replace(/<!--\s*?[^\s?[][\s\S]*?-->/g, '')
		.replace(/>\s*</g, '><')
		.trim();
};
