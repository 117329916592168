import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Dialog } from '~served/ui-comps';
import { dayjs } from '~served/utils';
import { useGetCurrentLocationCache } from '~/queries/useGetCurrentLocation';
import { useIsSessionExpired, useSetIsSessionExpired } from '~/store';
import { validateSessionFromUrl } from '~/utils/validateSessionFromUrl';
const SessionTrackerDialog = () => {
  const {
    replace
  } = useRouter();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [isRenewable, setIsRenewable] = useState(false);
  const isSessionExpired = useIsSessionExpired();
  const setIsSessionExpired = useSetIsSessionExpired();
  const {
    data: location
  } = useGetCurrentLocationCache();
  useEffect(() => {
    if (timer) clearInterval(timer);
    setTimer(setInterval(() => {
      const {
        isPathnameSessionSensitive,
        isSessionExpired,
        exp
      } = validateSessionFromUrl();
      if (!isPathnameSessionSensitive()) {
        setIsSessionExpired(false);
        return;
      }
      setIsSessionExpired(isSessionExpired());
      const maxRenewableThreshold = dayjs(exp).add(30, 'm');
      setIsRenewable(dayjs().isBefore(maxRenewableThreshold));
    }, 1000));
  }, []);
  if (!isSessionExpired) return null;
  return <Dialog dialogProps={{
    maxWidth: 'xs'
  }} title="OOPS!!! Session Expired 🤧" content={isRenewable ? "If you're at the same location, click REFRESH below, otherwise click SCAN QR to scan at new location." : 'Your session has expired. Scan the QR again.'} cancelButtonProps={isRenewable ? {
    children: 'Scan QR',
    onClick: () => replace('/')
  } : undefined} actionButtonProps={isRenewable ? {
    children: 'Refresh',
    onClick: () => replace(process.env.HUB_REST_URL + '/q/' + location?.hash)
  } : {
    children: 'Scan QR',
    onClick: () => replace('/')
  }} data-sentry-element="Dialog" data-sentry-component="SessionTrackerDialog" data-sentry-source-file="SessionTrackerDialog.tsx" />;
};
export { SessionTrackerDialog };