import { useMutation } from '@tanstack/react-query';

import { hubGqlClient } from '~/libs/gql';
import { setState } from '~/store';
import { graphql } from '~/types/__generated/gql';
import { CreateQrNotificationMutationVariables } from '~/types/__generated/gql/graphql';

const query = /* GraphQL */ `
	mutation CreateQrNotification($input: CreateQrNotificationInput!) {
		createQrNotification(input: $input) {
			_id
			created_at
			updated_at
			content
		}
	}
`;

const useSendAssistance = () => {
	return useMutation({
		mutationFn: (variables: CreateQrNotificationMutationVariables) =>
			hubGqlClient.request(graphql(query), variables),
		onSuccess: async () => {
			setState({ info: 'Assistance sent! Hang tight for a minute or 2 😇', isNeededHelp: false });
		},
	});
};

export { useSendAssistance };
