import { bigMath } from '../misc';

type CurrencyConfig = {
	code: string;
	rate: number;
	previous_rate?: number;
	is_primary_currency: boolean;
	is_secondary_currency: boolean;
	is_deactivated: boolean;
};

function convertCurrencies<T extends string>(
	baseValue: number,
	baseCurrency: T,
	currenciesConfigs: CurrencyConfig[]
): { [K in T]: number } {
	if (!currenciesConfigs.length) throw new Error('No configs currencies to work with');
	if (currenciesConfigs.findIndex((c) => c.code === baseCurrency) === -1)
		throw new Error('Base currency is not valid or base currency does not exist in configured currencies');

	return Object.fromEntries(currenciesConfigs.map((c) => [c.code, bigMath.mul(baseValue, c.rate)])) as {
		[K in T]: number;
	};
}

export { convertCurrencies };
export type { CurrencyConfig };
