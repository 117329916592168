import { styled } from '@mui/material/styles';

const Styled = {
	Container: styled('div')(() => {
		return {
			width: 300,
			padding: 8,
		};
	}),
};

export { Styled };
